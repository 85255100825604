import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthenticatedUser } from '../utils/getAuthenticatedUser';

const Callback: React.FC = () => {
    const navigate = useNavigate();
    const isMounted = useRef(true); // use ref to track mounting status

    useEffect(() => {
        const checkUser = async () => {
            try {
                const { user } = await getAuthenticatedUser();
                if (user && isMounted.current) {
                    console.log('User:', user, isMounted.current);
                    navigate('/home');
                } else {
                    console.log('No user found');
                    navigate('/');
                }
            } catch (error) {
                console.error('Failed to check user:', error);
                if (isMounted.current) {
                    navigate('/');
                }
            }
        };

        checkUser();
        // Cleanup function to set isMounted to false when component unmounts
        return () => {
            isMounted.current = false;
        };
    }, [navigate]);

    return (
        <div>Loading...</div>
    );
};

export default Callback;
