import { getCurrentUser, 
    //fetchAuthSession,  
    signOut, 
    type AuthUser } 
from 'aws-amplify/auth';

const getAuthenticatedUser = async () => {
    //let authToken: string = "";
    //let session = null;
    let user: AuthUser | null = null;

    try {
        user = await getCurrentUser();

        if(user) {
            console.log('User found:', user);
            //const { tokens } = await fetchAuthSession();
            //session = tokens;
        } else {
            console.log('No user found');
        }

        //authToken = session?.idToken?.toString() ?? '';
        
    } catch (error) {
        console.log('Error fetching credentials:', error);
    }

    return {
      user
    };
}

const signOutCurrentUser = async () => {
    try {
        console.log('Signing out user');
        return await signOut({ global: true });
    } catch (err) {
        console.log(err);
        return false;
    }
}

export  { 
    getAuthenticatedUser,
    signOutCurrentUser
}