import { signOut } from 'aws-amplify/auth';

const SignOut = () => {
    const signOutCurrentUser = async () => {
        try {
            console.log('Signing out user');
            return await signOut({ global: true });
        } catch (err) {
            console.log(err);
            return false;
        }
    }

  return (
    <button onClick={signOutCurrentUser}>Sign Out</button>
  );
};



export default SignOut;