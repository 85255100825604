import React, { ReactNode } from 'react';
import { Layout as AntLayout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import './layout.css';
import Nav from '../Nav';

const { Header, Content, Footer } = AntLayout;

interface LayoutProps {
    children: ReactNode;
  }

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <AntLayout className="layout">
      <Header className="header">
        <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
          <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="2"><Nav /></Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Awarity.ai</Footer>
    </AntLayout>
  );
};

export default Layout;
