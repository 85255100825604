import React, { useState } from 'react';
import useSWR from 'swr';
import { Card } from 'antd';

const api = 'https://api.portal.awarity.ai';

interface Host {
    group_name: string;
    hostname: string;
}

const mockData: Host[] = [
    { group_name: "Local-1", hostname: 'http://localhost:3001' },
    { group_name: "Local-2", hostname: 'http://localhost:3002 ' },
];

const fetcher = async (url: string): Promise<Host[]> => {

    //return Promise.resolve(mockData);

    const response = await fetch(url,
        {
            credentials: 'include'
        }
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

const validateAndTrimHostname = (hostname: string): string | null => {
    if (typeof hostname !== 'string' || hostname.trim() === '') {
        return null; // Return null if hostname is not a string or is empty
    }

    const trimmedHostname = hostname.trim();
    try {
        new URL(trimmedHostname); // Check if the hostname is a valid URL
        return trimmedHostname; // Return the trimmed hostname if it's valid
    } catch (e) {
        return null; // Return null if hostname is not a valid URL
    }
};

const ValidateGroupName = (groupName: string): string | null => {
    if (typeof groupName !== 'string' || groupName.trim() === '') {
        return null; // Return null if groupName is not a string or is empty
    }

    return groupName.trim().replace(/-/g, ' ');;
}

const Hosts: React.FC = () => {
    const [retryCount, setRetryCount] = useState(0);

    const { data, error, isValidating } = useSWR<Host[]>(`${api}/services`, fetcher, {
        revalidateOnFocus: false,
        onError: (err) => {
            console.error('Error fetching data:', err);
        },
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (error.status === 404) return;
            if (retryCount >= 3) {
                setRetryCount(retryCount);
                return;
            }
            revalidate({ retryCount })
        },
    });

    if (retryCount >= 3 && error) return <div>No hosts found.</div>;
    if (isValidating) return <div>Loading...</div>;

    return (
        <div>
            {data && data.length > 0 ? (
                <Card title="Awarity Servers" style={{ marginBottom: '16px' }}>
                    {
                        data.map(({ group_name, hostname }, index) => {
                            const validHostname = validateAndTrimHostname(hostname);
                            const validGroupName = ValidateGroupName(group_name);

                            if (!validGroupName) {
                                console.error('Invalid group name:', group_name);
                                return null;
                            }
                            if (!validHostname) {
                                console.error('Invalid hostname:', hostname);
                                return null;
                            }

                            return (
                                <p key={index}>
                                    <a href={`${validHostname}/chat`} target="_blank" rel="noopener noreferrer">
                                        <h4 style={{textTransform: 'capitalize', display: 'inline'}}>{validGroupName}</h4>
                                    </a>
                                </p>
                            );
                        })
                    }
                </Card>
            ) : (
                !isValidating && <div>No servers available.</div>
            )}
        </div>
    );
};

export default Hosts;
