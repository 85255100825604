import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import { getAuthenticatedUser, signOutCurrentUser } from '../utils/getAuthenticatedUser';

const Nav: React.FC = () => {
  const [authState, setAuthState] = useState<any | null>(null);
  const [user, setUser] = useState<any | null>(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);    

  useEffect(() => {
    console.log('Auth State:', authState);
    
    const checkUser = async () => {
        const { user } = await getAuthenticatedUser();
        setUser(user);
        setIsAuthenticated(user ? true : false);
    }

    checkUser();
}, [authState]);

  useEffect(() => {
    const removeAuthListener = Hub.listen('auth', (event) => {
        const { payload } = event;
        console.log('Auth event:', event);
        setAuthState(payload.event);
        switch (payload.event) {
          case 'signedIn':
            console.log('user have been signedIn successfully.');
            break;
          case 'signedOut':
            console.log('user have been signedOut successfully.');
            break;
          case 'tokenRefresh':
            console.log('auth tokens have been refreshed.');
            break;
          case 'tokenRefresh_failure':
            console.log('failure while refreshing auth tokens.');
            break;
          case 'signInWithRedirect':
            console.log('signInWithRedirect API has successfully been resolved.');
            break;
          case 'signInWithRedirect_failure':
            console.log('failure while trying to resolve signInWithRedirect API.');
            break;
          case 'customOAuthState':
            console.info('custom state returned from CognitoHosted UI');
            break;
        }
    });

    return () => {
        removeAuthListener();
    };
  }, []);
//<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end", margin:"20px"}}>
    
  return (
    <div className="nav-container">
      {
        isAuthenticated ? (
            <>Welcome, {user.username}! (<span onClick={signOutCurrentUser} style={{cursor: 'pointer', textDecoration: 'underline'}}>Sign out</span>)</>
      ) : null
      }
    </div>
  );
};

export default Nav;
