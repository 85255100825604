const awsConfig = {
    "Auth": {
       "Cognito": {
            "region": process.env.REACT_REGION!,
            "userPoolId": process.env.REACT_APP_USER_POOL_ID!,
            "userPoolClientId": process.env.REACT_APP_USER_POOL_CLIENT_ID!, //this is the public client key 
            "authenticationFlowType": 'USER_SRP_AUTH',
            oauth: {
                domain: process.env.REACT_APP_COGNITO_DOMAIN!,
                scope: ['email', 'openid'],
                redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN!,
                redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT!,
                responseType: 'code', // For Authorization code grant
              }
        }
        
    }
}
export default awsConfig;
