import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsConfig from './config/aws-cognito';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Callback from './components/Callback';
import SignOut from './components/SignOut';
import Hosts from './components/Hosts';
import Layout from './components/Layout';

Amplify.configure(awsConfig);

type SameSite = 'strict' | 'lax' | 'none';

const cookieStorageCongig = {
  domain: process.env.REACT_APP_PORTAL_DOMAIN, //'.portal.awarity.ai',
  path: '/',
  expires: 30, // days until cookie expiration
  secure: process.env.REACT_APP_COOKIE_STORAGE_SECURE === 'true', // set to true for HTTPS only
  sameSite: 'lax' as SameSite
}

const cookieStorage = new CookieStorage(cookieStorageCongig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

const Home = () => <div><Hosts /></div>;
const SignIn = () => <div>SignIn Page</div>;

export function App() {
  return (
    <div style={{ height: "100vh"}}>
      <Router>
        <Layout>
            <Routes>
                <Route path="/callback" element={<Callback />} />
                <Route path="/home" element={<Home />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signout" element={<SignOut />} /> 
                <Route path="/" element={<Home />} />
            </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true});